<template>
    <div>
        <Dialog :content="content" :show="dialogA" />
        <v-card class="mx-auto" margin="10px">
            <v-card-text>
                <v-btn-toggle class="align-center" align-center v-model="activeSection" tile
                    color="deep-purple accent-3" group>
                    <v-btn value="conference" href="/publications#Conferencepapers">
                        Conference papers
                    </v-btn>

                    <v-btn value="journal" href="/publications#Journals">
                        Journals
                    </v-btn>

                    <v-btn value="book" href="/publications#Books">
                        Books
                    </v-btn>


                </v-btn-toggle>
            </v-card-text>
        </v-card>
        <div>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
        </div>

        <v-card v-if="activeSection === 'conference'" class="mx-auto">
            <v-card-text class="py-0">
                <v-list>
                    <template v-for="(conference) in conferencepapers">
                        <v-list-item :key="conference.name">
                            <v-list-item-content>
                                <div class="d-flex justify-start pr-3"><v-list-item-title v-html="conference.name"
                                        class="font-weight-medium text-wrap"></v-list-item-title>
                                </div>
                                <v-list-item-title v-html="conference.authors"
                                    style="white-space: normal"><strong>Authors:</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="conference.journal"
                                    class="text-wrap"><strong>Journal:</strong></v-list-item-subtitle>
                                <v-list-item-subtitle class="text-wrap">
                                    <span v-if="conference.paperLink" class="mr-2">
                                        <a :href="conference.paperLink" target="_blank"><i
                                                class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                                    </span>
                                    <span v-if="conference.pdfLink" class="mr-2">
                                        <a :href="conference.pdfLink" target="_blank"><i
                                                class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                                    </span>
                                    <span v-if="conference.preprintLink" class="mr-2">
                                        <a :href="conference.preprintLink" target="_blank"><i
                                                class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                                    </span>
                                    <span v-if="conference.codeLink" class="mr-2">
                                        <a :href="conference.codeLink" target="_blank"><i
                                                class="fa-solid fa-code"></i>&nbsp;Code</a>
                                    </span>
                                    <span v-if="conference.slideLink" class="mr-2">
                                        <a :href="conference.slideLink" target="_blank"><i
                                                class="fa-solid fa-display"></i>&nbsp;Slides</a>
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>

                    </template>
                </v-list>
            </v-card-text>
        </v-card>

        <v-card v-if="activeSection === 'journal'" class="mx-auto">
            <v-card-text class="py-0">
                <v-list>
                    <template v-for="(journal) in journals">
                        <v-list-item :key="journal.name">
                            <v-list-item-content>
                                <div class="d-flex justify-start pr-3"><v-list-item-title v-html="journal.name"
                                        class="font-weight-medium text-wrap"></v-list-item-title>
                                </div>
                                <v-list-item-title v-html="journal.authors"
                                    style="white-space: normal"><strong>Authors:</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="journal.journal"
                                    class="text-wrap"><strong>Journal:</strong></v-list-item-subtitle>
                                <v-list-item-subtitle class="text-wrap">
                                    <span v-if="journal.paperLink" class="mr-2">
                                        <a :href="journal.paperLink" target="_blank"><i
                                                class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                                    </span>
                                    <span v-if="journal.pdfLink" class="mr-2">
                                        <a :href="journal.pdfLink" target="_blank"><i
                                                class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                                    </span>
                                    <span v-if="journal.preprintLink" class="mr-2">
                                        <a :href="journal.preprintLink" target="_blank"><i
                                                class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                                    </span>
                                    <span v-if="journal.codeLink" class="mr-2">
                                        <a :href="journal.codeLink" target="_blank"><i
                                                class="fa-solid fa-code"></i>&nbsp;Code</a>
                                    </span>
                                    <span v-if="journal.slideLink" class="mr-2">
                                        <a :href="journal.slideLink" target="_blank"><i
                                                class="fa-solid fa-display"></i>&nbsp;Slides</a>
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>



        <v-card v-if="activeSection === 'book' " class="mx-auto">
            <v-card-text class="py-0">
                <v-list>
                    <template v-for="(book) in books">
                        <v-list-item :key="book.name">
                            <v-list-item-content>
                                <div class="d-flex justify-start pr-3"><v-list-item-title v-html="book.name"
                                        class="font-weight-medium text-wrap"></v-list-item-title>
                                </div>
                                <v-list-item-title v-html="book.authors"
                                    style="white-space: normal"><strong>Authors:</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="book.journal"
                                    class="text-wrap"><strong>Journal:</strong></v-list-item-subtitle>
                                <v-list-item-subtitle class="text-wrap">
                                    <span v-if="book.paperLink" class="mr-2">
                                        <a :href="book.paperLink" target="_blank"><i
                                                class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                                    </span>
                                    <span v-if="book.pdfLink" class="mr-2">
                                        <a :href="book.pdfLink" target="_blank"><i
                                                class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                                    </span>
                                    <span v-if="book.preprintLink" class="mr-2">
                                        <a :href="book.preprintLink" target="_blank"><i
                                                class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                                    </span>
                                    <span v-if="book.codeLink" class="mr-2">
                                        <a :href="book.codeLink" target="_blank"><i
                                                class="fa-solid fa-code"></i>&nbsp;Code</a>
                                    </span>
                                    <span v-if="book.slideLink" class="mr-2">
                                        <a :href="book.slideLink" target="_blank"><i
                                                class="fa-solid fa-display"></i>&nbsp;Slides</a>
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>

    </div>
</template>


<script>
import PUBLICATIONS from './json/journals.json';
import CONFERENCEPAPERS from './json/conferences.json';
import BOOKS from './json/books.json';
import Dialog from '../components/Dialog.vue'
import axios from 'axios';
import { reverse } from 'lodash';

export default {
    components: {
        Dialog
    },
    data: () => ({
        paper: 'paper-text-outline',
        journals: PUBLICATIONS,
        conferencepapers: CONFERENCEPAPERS,
        books: BOOKS,
        text: "",
        content: "",
        activeSection: 'conference',
        dialogA: false,
        items: [
        ],
    }),
    beforeMount() {
        this.journals = PUBLICATIONS;
        this.conferencepapers = CONFERENCEPAPERS;
        this.books = BOOKS.reverse();
    },

    methods: {
        getPublications() {
            axios.get('https://cyber-api.hellven.io/publications').then(response => {
                this.journals = reverse(response.data);

            });
        },
        openSource(item) {
            window.open(item.paperLink);
        },
        openAbstract(item) {
            this.dialogA = true;
            this.content = item.abstract;
            //console.log(item)
        }
    }

}
</script>
<style scoped>.routerLink {
    text-decoration: none;
}</style>