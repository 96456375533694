<template>
  <v-app>
    <v-main>
      <v-container class="d-flex">
        <!-- <v-app-bar app class="centered-app-bar"> -->
        <v-app-bar app>
          <v-img max-height="70" max-width="150" contain src="./views/images/hsg_logo.png"></v-img>
          <v-tabs v-model="activeTab">
            <v-tab @click="navigateTo('/')">Home</v-tab>
            <v-tab @click="navigateTo('/people')">People</v-tab>
            <v-tab @click="navigateTo('/research')">Research</v-tab>
            <v-tab @click="navigateTo('/publications')">Publications</v-tab>
            <v-tab @click="navigateTo('/courses')">Courses</v-tab>
            <v-tab @click="navigateTo('/thesis')">Thesis</v-tab>
            <v-tab @click="navigateTo('/contact')">Contact</v-tab>
          </v-tabs>
        </v-app-bar>
      </v-container>

      <v-container class="page-container">
        <router-view />
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
export default {

  name: "App",
  components: {},
  data: () => ({
    activeTab: '/',
  }),
  methods: {
    navigateTo(routePath) {
      this.activeTab = routePath;
      this.$router.push(routePath).catch(err => {
        if (err.name != "NavigationDuplicated" && !err.message.includes("Avoided redundant navigation to current location")) {
          console.log(err);
        }
      });
    },
  },
};
</script>

<style scoped>

.centered-app-bar {
  display: flex;
  justify-content: center;
}



.name {
  font-family: "Helvetica Neue";
  size: "16px";
  color: #555;
}

.nav-tabs .nav-link:focus {
  outline: none;
}

.underline {
  text-decoration: underline;
  font-family: "Helvetica Neue";
  color: #555;
}

.routerLink {
  text-decoration: none;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.v-list-item {
  flex: 0;
}

.titlePaper {
  color: #0e0ca6;
  font-family: "Arial";
  font-size: 18px;
}

.menu {
  color: #1a0dab;
  font-family: "Roboto";
  font-size: 17px;
}

.subtitle {
  color: #337ab7;
}

.avatarimg {
  border: 4px solid #ddd !important;
  display: block;
  background: #ffffff;
}

.page-container {
  max-width: 1200px !important;
}

.navbar {
  background: #f44336;
  padding: 1rem;
  font-size: 1.5rem;
  border-bottom: 1px solid white;
}

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@368&family=Space+Grotesk:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

html,
body {
  height: 100%;
  padding-top: 10px;
  background: #e6ecf1;
}

#app .r {
  font-family: "Rubik", sans-serif;
}

#app .button {
  margin-bottom: 20px;
}

#app .img {
  border-radius: 30px;
}

#app .level-item {
  padding-left: 10px;
  color: #00d1b2;
}

#app input:focus {
  border-color: #00d1b2;
}
</style>
