<template>

  <div id="contact">

    <h6 class="font-weight-regulartext-left grow mt-5 ml-3">How to find us</h6>
    <GoogleMap />
    <h6 class="font-weight-regulartext-left grow mt-5 ml-3"> Contact us</h6>
    <div class="contact-item-container">
      <v-list dense>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-domain
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>University of St. Gallen</v-list-item-title>
            <v-list-item-subtitle>Chair of Cybersecurity</v-list-item-subtitle>
            <v-list-item-subtitle>Prof. Dr. Katerina Mitrokotsa</v-list-item-subtitle>
            <v-list-item-subtitle>Torstrasse 25</v-list-item-subtitle>
            <v-list-item-subtitle>CH-9000 St. Gallen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-email
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title style="font-family:'Roboto' size:'13px'">
            <!-- <a class="routerLink" href="mailto:'cyber@gmail.com'">katerina.mitrokotsa(at)unisg.ch</a> -->
            katerina.mitrokotsa(at)unisg.ch
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-phone
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title style="font-family:'Roboto' font-size:'0.8em'">
            <a class="routerLink" href="tel:+41 712 247 861">+41 712 247 861</a>
          </v-list-item-title>
        </v-list-item>


      </v-list>
      <v-list dense>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-domain
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>University of St. Gallen</v-list-item-title>
            <v-list-item-subtitle>Chair of Cybersecurity</v-list-item-subtitle>
            <v-list-item-subtitle>Eriane Breu</v-list-item-subtitle>
            <v-list-item-subtitle>Torstrasse 25</v-list-item-subtitle>
            <v-list-item-subtitle>CH-9000 St. Gallen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-email
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title style="font-family:'Roboto' size:'13px'">
            <!-- <a class="routerLink" href="mailto:'cyber@gmail.com'">katerina.mitrokotsa(at)unisg.ch</a> -->
            eriane.breu(at)unisg.ch
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-phone
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title style="font-family:'Roboto' font-size:'0.8em'">
            <a class="routerLink" href="tel:+41 712 247 861">+41 712 247 861</a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>


<script>
  import GoogleMap from '../components/GoogleMap.vue'
  export default {
    name: 'Contact',
    components: {
        GoogleMap
    }
  }
</script>

<style scoped>
.navbar{
    background: #f44336;
    padding: 1rem;
    font-size: 1.5rem;
    border-bottom: 1px solid white;


}
.h2{
  font-family: 'Helvetica Neue';
  text-align : 'center';
  padding: 1rem;
  color:#555555;

}
.contact-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
