<template>
  <div class="container">
    <div class="left-column">
      <div class="image-container centered">
        <img :src="profileImage" class="shrink-circular-image" />
      </div>
      <h2 class="h2_gap">{{ dd.fullname }}</h2>
      <p><i class="fa-solid fa-address-card"></i> <i>{{ dd.position }}</i></p>
      <p><i class="fa-solid fa-envelope"></i> {{ dd.email }}</p>
      <p v-if="dd.homepage"><i class="fas fa-globe"></i> <a :href="dd.homepage" target="_blank">Homepage</a></p>
      <p v-if="dd.linkedin"><i class="fa-brands fa-linkedin"></i> <a :href="dd.linkedin" target="_blank">LinkedIn</a>
      </p>
      <p v-if="dd.github"><i class="fa-brands fa-github"></i> <a :href="dd.github" target="_blank">Github</a></p>
      <p v-if="dd.scholar"><i class="fa-brands fa-google"></i> <a :href="dd.scholar" target="_blank">Google Scholar</a>
      </p>
      <p v-if="dd.dblp"><i class="fa-solid fa-circle-nodes"></i> <a :href="dd.dblp" target="_blank">DBLP</a></p>

    </div>
    <div class="middle-column" style="margin-left: 15px;">
      <div v-if="dd.description" v-html="dd.description" style="text-align: justify"></div>
      <div>
        <v-btn-toggle class="align-center" align-center v-model="activeSection" tile color="deep-purple accent-3" group>
          <v-btn v-if="showConferences" value="conference" href="#Conferencepapers">
            Conference papers
          </v-btn>
          <v-btn v-if="showJournals" value="journal" href="#Journals">
            Journals
          </v-btn>
          <v-btn v-if="showBooks" value="book" href="#Books">
            Books
          </v-btn>
        </v-btn-toggle>
        <div>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </div>

        <v-card v-if="activeSection === 'conference'" class="mx-auto">
          <v-card-text class="py-0">
            <v-list>
              <template v-for="conference in conferences">
                <v-list-item :key="conference.name">
                  <v-list-item-content>
                    <div class="d-flex justify-start pr-3"><v-list-item-title v-html="conference.name"
                        class="font-weight-medium text-wrap"></v-list-item-title>
                    </div>
                    <v-list-item-title class="text-wrap">
                      <span v-html="conference.authors"></span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      <span v-html="conference.journal"></span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap">
                      <span v-if="conference.paperLink" class="mr-2">
                        <a :href="conference.paperLink" target="_blank"><i
                            class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                      </span>
                      <span v-if="conference.pdfLink" class="mr-2">
                        <a :href="conference.pdfLink" target="_blank"><i class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                      </span>
                      <span v-if="conference.preprintLink" class="mr-2">
                        <a :href="conference.preprintLink" target="_blank"><i
                            class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                      </span>
                      <span v-if="conference.codeLink" class="mr-2">
                        <a :href="conference.codeLink" target="_blank"><i class="fa-solid fa-code"></i>&nbsp;Code</a>
                      </span>
                      <span v-if="conference.slideLink" class="mr-2">
                        <a :href="conference.slideLink" target="_blank"><i
                            class="fa-solid fa-display"></i>&nbsp;Slides</a>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>


        <v-card v-if="activeSection === 'journal'" class="mx-auto">
          <v-card-text class="py-0">
            <v-list>
              <template v-for="journal in journals">
                <v-list-item :key="journal.name">
                  <v-list-item-content>
                    <div class="d-flex justify-start pr-3"><v-list-item-title v-html="journal.name"
                        class="font-weight-medium text-wrap"></v-list-item-title>
                    </div>

                    <!-- <v-list-item-title v-html="journal.authors" style="white-space: normal"><strong>Authors:</strong>
                    </v-list-item-title> -->

                    <v-list-item-title class="text-wrap">
                      <span v-html="journal.authors"></span>
                    </v-list-item-title>


                    <v-list-item-subtitle class="text-wrap">
                      <span v-html="journal.journal"></span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap">
                      <span v-if="journal.paperLink" class="mr-2">
                        <a :href="journal.paperLink" target="_blank"><i
                            class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                      </span>
                      <span v-if="journal.pdfLink" class="mr-2">
                        <a :href="journal.pdfLink" target="_blank"><i class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                      </span>
                      <span v-if="journal.preprintLink" class="mr-2">
                        <a :href="journal.preprintLink" target="_blank"><i
                            class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                      </span>
                      <span v-if="journal.codeLink" class="mr-2">
                        <a :href="journal.codeLink" target="_blank"><i class="fa-solid fa-code"></i>&nbsp;Code</a>
                      </span>
                      <span v-if="journal.slideLink" class="mr-2">
                        <a :href="journal.slideLink" target="_blank"><i class="fa-solid fa-display"></i>&nbsp;Slides</a>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card v-if="activeSection === 'book' " class="mx-auto">
          <v-card-text class="py-0">
            <v-list>
              <template v-for="book in books">
                <v-list-item :key="book.name">
                  <v-list-item-content>
                    <div class="d-flex justify-start pr-3"><v-list-item-title v-html="book.name"
                        class="font-weight-medium text-wrap"></v-list-item-title>
                    </div>

                    <v-list-item-title class="text-wrap">
                      <span v-html="book.authors"></span>
                    </v-list-item-title>


                    <!-- <v-list-item-title v-html="book.authors" style="white-space: normal"><strong>Authors:</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="book.journal"
                      class="text-wrap"><strong>Journal:</strong></v-list-item-subtitle> -->


                    <v-list-item-subtitle class="text-wrap">
                      <span v-html="book.journal"></span>
                    </v-list-item-subtitle>


                    <v-list-item-subtitle class="text-wrap">
                      <span v-if="book.paperLink" class="mr-2">
                        <a :href="book.paperLink" target="_blank"><i
                            class="fa-solid fa-file-lines"></i>&nbsp;Publication</a>
                      </span>
                      <span v-if="book.pdfLink" class="mr-2">
                        <a :href="book.pdfLink" target="_blank"><i class="fa-solid fa-file-pdf"></i>&nbsp;PDF</a>
                      </span>
                      <span v-if="book.preprintLink" class="mr-2">
                        <a :href="book.preprintLink" target="_blank"><i class="fa-solid fa-print"></i>&nbsp;Preprint</a>
                      </span>
                      <span v-if="book.codeLink" class="mr-2">
                        <a :href="book.codeLink" target="_blank"><i class="fa-solid fa-code"></i>&nbsp;Code</a>
                      </span>
                      <span v-if="book.slideLink" class="mr-2">
                        <a :href="book.slideLink" target="_blank"><i class="fa-solid fa-display"></i>&nbsp;Slides</a>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>

      </div>
    </div>
  </div>
</template>

<style>
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-column {
  width: 30%;
}

.middle-column {
  width: 70%;
}

.image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

ul {
  padding: 0;
  /* Remove default padding */
  list-style: none;
  /* Remove list bullet/number */
}

li {
  margin: 0px;
  /* Adjust the margin as needed to reduce vertical spacing */
}

li p {
  line-height: 0.9;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shrink-circular-image {
  width: 100%;
  height: auto;
}

.h2_gap {
  margin-top: 40px;
  /* Increase the space between the image and full name */
}
</style>

<script>
export default {
  data() {
    return {
      activeSection: 'conference',

      dd: null,
      journals: [],
      conferences: [],
      books: [],
      profileImage: ''
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  async created() {
    const jsonFileMapping = {
      Katerina: 'katerina.json',
      Eriane: 'eriane.json',
      // Kolja: 'kolja.json',
      // Uddipana: 'uddipana.json',
      Johannes: 'johannes.json',
      Nan: 'nan.json',
      Jenit: 'jenit.json',
      Alice: 'alice.json',
      Florias: 'florias.json',
      Subhranil: 'subhranil.json',
      Jannis: 'jannis.json'
    };
    const jsonFile = jsonFileMapping[this.id];

    try {
      this.dd = require(`./json/members/${jsonFile}`);
    } catch (error) {
      console.error(`Error loading JSON data: ${error}`);
    }

    const journalFileMapping = {
      Katerina: 'katerina_journal.json',
      // Kolja: 'kolja_journal.json',
      // Uddipana: 'uddipana_journal.json',
      Johannes: 'johannes_journal.json',
      Nan: 'nan_journal.json',
      Jenit: 'jenit_journal.json',
      Alice: 'alice_journal.json',
      Florias: 'florias_journal.json',
      Jannis: 'jannis_journal.json',
      Subhranil: 'subhranil_journal.json'
    };

    const journalFile = journalFileMapping[this.id];

    try {
      this.journals = require(`./json/journals/${journalFile}`);
      this.showJournals = this.journals.length > 0;
    } catch (error) {
      console.error(`Error loading JSON data: ${error}`);
    }

    const conferenceFileMapping = {
      Katerina: 'katerina_conference.json',
      // Kolja: 'kolja_conference.json',
      // Uddipana: 'uddipana_conference.json',
      Johannes: 'johannes_conference.json',
      Nan: 'nan_conference.json',
      Jenit: 'jenit_conference.json',
      Alice: 'alice_conference.json',
      Florias: 'florias_conference.json',
      Jannis: 'jannis_conference.json',
      Subhranil: 'subhranil_conference.json'
    };
    const conferenceFile = conferenceFileMapping[this.id];

    try {
      this.conferences = require(`./json/conferences/${conferenceFile}`);
      this.showConferences = this.conferences.length > 0;
    } catch (error) {
      console.error(`Error loading JSON data: ${error}`);
    }

    const bookFileMapping = {
      Katerina: 'katerina_book.json',
      // Kolja: 'kolja_book.json',
      // Uddipana: 'uddipana_book.json',
      Johannes: 'johannes_book.json',
      Nan: 'nan_book.json',
      Jenit: 'jenit_book.json',
      Alice: 'alice_book.json',
      Florias: 'florias_book.json'
    };
    const bookFile = bookFileMapping[this.id];

    try {
      this.books = require(`./json/books/${bookFile}`);
      this.showBooks = this.books.length > 0;
    } catch (error) {
      console.error(`Error loading JSON data: ${error}`);
    }

    this.profileImage = this.imgPath();

    if(this.showConferences){
      this.activeSection = 'conference';
    } else if (this.showJournals) {
      this.activeSection = 'journal';
    } else if (this.showBooks) {
      this.activeSection = 'book';
    }else{
      this.activeSection = 'none';
    }
  },
  methods: {
    imgPath() {
      // Determine the image filename based on the route parameter
      const imageFileName = require('./images/members/' + this.id + '.jpeg');

      return imageFileName;
    },
  },
};
</script>