<template>
    <div>
        <div id="courses" class="align-center mx-auto" >
            <!-- <v-img class="mx-auto my-4" contain  max-height="160" max-width="620"  src="@/assets/privacy.jpeg"></v-img> -->
        
 
        <v-card class="my-3" margin="5px" >
        <v-list dense >
            <template v-for="(course) in courses">
                <v-list-item :key="course.name" >  
                    <v-col cols ="3" align-self="start">
                        <v-img  :src="getImagePath(course.image)" max-width="220"></v-img>
                    </v-col>
                    <v-col>
                        <v-list-item-content class="my-3">
                            <div class="d-flex my-3">
                            <p class="mr-2">
                                <strong class ="r">{{course.title}}</strong>
                            </p>
                            {{course.sm}}
                            <a v-if="course.pdf" class="routerLink" :href="course.pdf">&nbsp;FactSheet</a>
                        </div>
                            <div class="text--primary ofont font-weight-regular" v-html="course.description" > </div>              
                        </v-list-item-content>  
                    </v-col> 
                </v-list-item>
            </template>
        </v-list>
        </v-card>
       </div>
		
	
    </div>  
</template>


<script>
    import COURSES from './json/courses.json';
    export default {
        data: () => ({
            courses : COURSES,
            text : "",
            content :"",
            dialogA: false,
    }),
    beforeMount() {
        this.courses =COURSES;
    },
    methods: {
        getImagePath(filename) {
          return require('./images/courses/' + filename);
        }
    }
  }
</script>


<style scoped>
    .ht {
        color : #074a0e
    }
    .content strong {
        color: #00783a;
    }

    .r {
       font-family: 'Rubik', sans-serif;
     
    }

    .routerLink{
        text-decoration: none;
    }
</style>