<template>
    <div>
        <div class="centered-card">
            <v-card margin="10px" elevation="0" class="limited-width">
                <v-card-text style="font-size: 15px;">
                    <div class="text--primary" style="text-align: justify">
                        Our group offers thesis supervision for HSG students in the Bachelor Computer Science and Master
                        Computer
                        Science
                        programmes.
                        <br>
                        <br>
                        Our research interests are centered around information security and applied cryptography.
                        Some of our key interests:
                        <br>
                        <br>

                        <ul class="custom-bullet-list">
                            <li>Functional encryption</li>
                            <li>Verifiable computation</li>
                            <li>Secure, private and distributed aggregation</li>
                            <li>Secure multi-party computation</li>
                            <li>Privacy-preserving biometric authentication</li>
                            <li>Proximity-based authentication</li>
                        </ul>

                        The members of our group may supervise topics related to these interests. Of course we are
                        not limited to them and may also find other topics related to cyber security and
                        cryptography.
                        <br>
                        <br>
                        Feel free to contact any member of the group regarding a possible thesis. Of course you can
                        come forward with your own topic and we will try to make it work out.
                    </div>
                </v-card-text>
                <div v-if="showAvailableThesis">
                    <h3 style="margin-top: 15px;">Available thesis:</h3>
                    <ul>
                        <li v-for="thesis in available_thesis" :key="thesis.name">
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Title:</strong> {{ thesis.name }}
                            </p>
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Student:</strong> {{ thesis.student
                                }}</p>
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Supervisors:</strong> {{
                                thesis.supervisor }}</p>
                        </li>
                    </ul>
                </div>

                <div v-if="showRunningThesis">
                    <h3 style="margin-top: 15px;">Running thesis:</h3>
                    <ul>
                        <li v-for="thesis in running_thesis" :key="thesis.name" class="thesis-item">
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Title:</strong> {{ thesis.name }}
                            </p>
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Student:</strong> {{ thesis.student
                                }}</p>
                            <p style="margin-top: 8px; margin-bottom: 8px;"><strong>Supervisors:</strong> {{
                                thesis.supervisor }}</p>
                        </li>
                    </ul>
                </div>
            </v-card>
        </div>
    </div>
</template>
  
<style scoped>
.centered-card {
    display: flex;
    align-items: flex-start;/* Aligns children to the start of the container */
    justify-content: center;
}

.limited-width {
  max-width: 60%;
  width: 60%; /* Ensures the card takes up the available width up to the max-width */
}

li.thesis-item {
    border-bottom: 1px solid #ccc;
    /* Light gray border at the bottom */
    padding-bottom: 10px;
    /* Space between the content and the border */
    margin-bottom: 10px;
    /* Space between each list item */
}

</style>

<script>
export default {
    data() {
        return {
            available_thesis: [],
            running_thesis: [],
            showAvailableThesis: false,
            showRunningThesis: false,
        };
    },
    computed: {
    },
    async created() {
        try {
            this.available_thesis = require(`./json/available_thesis.json`);
            this.showAvailableThesis = this.available_thesis.length > 0;
        } catch (error) {
            console.error(`Error loading JSON data: ${error}`);
        }

        try {
            this.running_thesis = require(`./json/running_thesis.json`);
            this.showRunningThesis = this.running_thesis.length > 0;
        } catch (error) {
            console.error(`Error loading JSON data: ${error}`);
        }
    },
};
</script>
  
  
  
  
